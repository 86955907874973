import { put, select, delay, takeLatest } from "redux-saga/effects";
import { selectExpiresAt, selectIsLoggedIn, selectIsLoginInProgress } from "./selectors";
import { commonDapLoginTokenExpired } from "./login";
import { COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS } from "./constants";

const CHECK_AUTH_TOKEN_INTERVAL = 1000;
const AUTH_TOKEN_EXPIRATION_GRACE_PERIOD = 30 * 1000;

function* checkAuthTokenPeriodicallySagas() {
  while (true) {
    yield delay(CHECK_AUTH_TOKEN_INTERVAL);

    const isLoggedIn = yield select(selectIsLoggedIn);
    if (!isLoggedIn) return;

    const isLoginInProgress = yield select(selectIsLoginInProgress);
    if (isLoginInProgress) return;

    const tokenExpiresAt = yield select(selectExpiresAt);

    const dateNow = Date.now();
    const expiresAtTimestamp = new Date(tokenExpiresAt).getTime();

    if ((dateNow + AUTH_TOKEN_EXPIRATION_GRACE_PERIOD) > expiresAtTimestamp) {
      yield put(commonDapLoginTokenExpired());
    }
  }
}

export function* switchTokenChecker() {
  yield takeLatest(COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS, checkAuthTokenPeriodicallySagas);
}
